import InfoModal from "../modals/InfoModal";

export default {
    methods:{
        theModal(title, text, proceed = false, buttonText = "Продолжить") {
            this.$modal.show(
                InfoModal,
                {
                    title: title,
                    text: text,
                    icon: "Warning",
                    buttonText: buttonText,
                    proceed: proceed
                }, {
                    width: 626,
                    height: 'auto',
                    clickToClose: false,
                    scrollable: true,
                    name: "InfoModal",
                }, {
                    'before-open': this.beforeOpen,
                    'before-close': this.beforeClose,
                })
        },
        push({to, fundKI, title, isDetales, isQualifiedInvestor, kiStatus}) {
            if (isDetales && (fundKI && isQualifiedInvestor || !fundKI)) {
                this.$router.push(to)
                return
            } else if (!isDetales && fundKI && isQualifiedInvestor) {
                this.theModal(title, "Инвестиционные паи фондов предназначены для квалифицированных инвесторов " +
                    "(ограничены в обороте). Не допускается распространение информации о паевом " +
                    "инвестиционном фонде, инвестиционные паи которого ограничены в обороте, " +
                    "за исключением случаев ее раскрытия в соответствии с федеральными законами.");
            } else if (kiStatus == null){
                this.theModal(title,"Уважаемый клиент!<br>" +
                    "Инвестиционные паи данного фонда ограничены в обороте и предназначены исключительно для лиц, являющихся квалифицированными инвесторами.<br>" +
                    "Для получения подробной информации о данном фонде или возможности подать заявку на приобретение инвестиционных паев Вам необходимо подтвердить статус квалифицированного инвестора, подав в разделе «Документы» Заявление о признании физического лица квалифицированным инвестором и прикрепив сканы необходимых документов.",
                    {location: "/cabinet/documents/forms/ki?next=" + to },
                    "Заявление на признание КИ");
            } else {
                this.theModal(title,"Уважаемый клиент!<br>" +
                    "Инвестиционные паи данного фонда ограничены в обороте и предназначены исключительно для лиц, являющихся квалифицированными инвесторами.<br>" +
                    "Вам нужно дождаться обработки Ваших документов.");
            }
        },

        buy({toBuy, fundStatus, fundAnkets, title, fundKI, hasNecessaryDocs, fatca_status, podft_status, kiStatus, isQualifiedInvestor }) {
            let text
            if (fundStatus === 7) {
                text = "Уважаемый клиент!</br>" +
                    "В настоящий момент фонд не производит выдачу инвестиционных паев, однако Вы можете открыть лицевой счет в реестре владельцев инвестиционных паев, что позволит Вам сократить время на подачу заявок на приобретение в будущем."
                this.theModal(title, text, {toBuy, fundKI, title})
                return
            }
            if (toBuy && hasNecessaryDocs) {
                if (fundStatus === 7 &&
                    (fundAnkets?.find(el => el.type_id === 5) && fundAnkets?.find(el => el.type_id === 5).status_id === 3) &&
                    (fundAnkets?.find(el => el.type_id === 4) && fundAnkets?.find(el => el.type_id === 4).status_id === 3)
                ) {
                    this.theModal(title, "Уважаемый клиент! Приобретение паев в данном фонде пока недоступно")
                } else if (fundKI && isQualifiedInvestor || !fundKI) {
                    this.$router.push(`/cabinet/investment/purchase-of-investment-shares/${toBuy}`)
                } else if (kiStatus == 1) {
                    this.theModal(title,"Уважаемый клиент!<br>Для продолжения приобретения паев Вам нужно дождаться обработки Ваших документов.");
                } else {
                    this.theModal(title,
                        "Уважаемый клиент!<br>Для подачи заявки на приобретение инвестиционных паев Вам необходимо заполнить и отправить пакет документов",
                        {location: "/cabinet/investment/purchase-of-investment-shares/" + toBuy + "?next" },
                        "Заполнить документы");
                    /*text = "Уважаемый клиент!<br>" +
                        "Инвестиционные паи данного фонда ограничены в обороте и предназначены исключительно для лиц, являющихся квалифицированными инвесторами.<br>" +
                        "Для получения подробной информации о данном фонде или возможности подать заявку на приобретение инвестиционных паев Вам необходимо подтвердить статус квалифицированного инвестора, подав в разделе «Документы» Заявление о признании физического квалифицированным инвестором и прикрепив сканы необходимых документов."*/
                }
            } else if (!hasNecessaryDocs) {
                let proceed = false
                let buttonText = "Продолжить"
                if ((fatca_status == 1 || fatca_status == 3)
                    && (podft_status == 1 || podft_status == 3)
                    && (!fundKI || kiStatus == 1 || kiStatus == 3)
                    && (typeof fundAnkets?.find(el => el.type_id === 4 && (el.status_id === 1 || el.status_id === 3)) !== "undefined")
                    && (typeof fundAnkets?.find(el => el.type_id === 5 && (el.status_id === 1 || el.status_id === 3)) !== "undefined")
                ){
                    if (fundKI && !kiStatus) {
                        this.$router.push("/cabinet/documents/forms/ki")
                        return;
                    } else text = 'Уважаемый клиент!<br>Для продолжения приобретения паев Вам нужно дождаться обработки Ваших документов.'
                } else {
                    text = "Уважаемый клиент!<br>Для подачи заявки на приобретение инвестиционных паев Вам необходимо заполнить и отправить пакет документов<br>"
                    buttonText = "Заполнить документы"
                    proceed = {location: "/cabinet/investment/purchase-of-investment-shares/" + toBuy + "?next" }
                }
                this.theModal(title, text, proceed, buttonText)
            } else {
                if (fundStatus!==1) {
                    text = "Уважаемый клиент!<br>К сожалению, на текущий момент фонд не производит выдачу инвестиционных паев, мы обязательно сообщим Вам о факте начала дополнительного выпуска."
                } else {
                    text = "Уважаемый клиент!<br>" +
                        "На текущий момент правила доверительного управления фондом находятся на этапе разработки.<br>" +
                        "Мы обязательно сообщим Вам о дате начала формирования фонда."
                }

                this.theModal(title, text)
            }
        },
        beforeOpen() {
            let body = document.body
            body.classList.add('modal-open')
        },
        beforeClose() {
            let body = document.body
            body.classList.remove('modal-open')
        },
    }
}